import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

const ErrorPage = () => {
  return (
    <>
      <Helmet
        title="お探しのページが見つかりませんでした。 - マコム・プラニング"
        description="マコム・プラニング公式サイトの404ページ"
      />
      <Layout>
        <section className="relative lg:py-20 overflow-hidden">
          <img className="lg:absolute lg:top-0 lg:left-0 h-128 lg:h-auto max-h-screen w-full lg:w-5/12 object-cover" src="https://images.unsplash.com/photo-1544717305-996b815c338c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80" alt="" />
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap items-center">
              <div className="w-full lg:w-7/12 lg:ml-auto mt-12 mb-20 text-center">
                <span className="text-xs font-semibold text-gray-500 uppercase">Error 404</span>
                <h2 className="mt-8 mb-10 text-4xl font-semibold font-heading">ページが見つかりませんでした</h2>
                <p className="mb-12 text-xl text-gray-500">申し訳ございません、以下のボタンよりトップページにお戻りください。</p>
                <div className="flex flex-wrap justify-center">
                  <Link className="w-full md:w-auto px-8 py-4 mb-4 md:mb-0 md:mr-4 bg-red-400 hover:bg-red-300 text-sm text-white font-medium leading-normal rounded" to="/">トップページに戻る</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default ErrorPage
